import axios, { AxiosInstance, AxiosResponse } from 'axios';

abstract class HttpClient {
	protected readonly instance: AxiosInstance;

	protected constructor(baseURL: string | undefined) {
		this.instance = axios.create({
			baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		this.initializeResponseInterceptor();
	}

	private initializeResponseInterceptor = () => {
		this.instance.interceptors.response.use(this.handleSuccessResponse);
	};

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	private handleSuccessResponse = ({ data }: AxiosResponse) => data;
}

export default HttpClient;
