import { CancelTokenSource } from 'axios';
import HttpClient from './http-client';

export interface DataNoData {
	clinicIdFromDb: number;
	clinicProviderId: string;
	timeRangeEnd: string;
	timeRangeStart: string;
}

export interface ClinicsNoData {
	[key: string]: {
		data: DataNoData;
		error: string;
	};
}
export interface AppointmentTime {
	clinicDbId?: number;
	day: string;
	durationInMinutes: number;
	id: string;
	isAvailable: boolean;
	practitionerIdFromProvider: number | null;
	startHours: number;
	startMinutes: number;
}

export interface ClinicsAvailability {
	[key: string]: AppointmentTime[];
}

export interface ClinicsAvailabilityDataExpires {
	data: ClinicsAvailability;
	expires: number;
}

export interface Availability {
	available: ClinicsAvailabilityDataExpires;
	noData: ClinicsNoData[];
}

export interface AppointmentData {
	email: string;
	firstName: string;
	lastName: string;
	gender: string;
	dateOfBirth: string;
	insurance: string;
	phone: string;
	availabilityId: string;
	recaptchaToken: string;
	confirmation: boolean | null;
}

export interface ConnectedClinic {
	apiKey: string;
	name: string;
	suburb: string;
	provider?: string;
}

export interface Item {
	name: string;
	slug: string;
}
export interface State {
	slug: string;
	abbreviation: string;
	meta_title: string;
	meta_description: string;
	h3: string;
	clinics: Item[];
	suburbs: Item[];
}

export interface ISectionLink {
	link: string;
	linkText: string;
}

export interface IPageSection {
	h2: string;
	sectionLinks: ISectionLink[];
}

export interface SitemapItemData {
	id: number;
	slug: string;
	h1: string;
	metaTitle: string;
	pageSections: IPageSection[];
}

class DynamicData extends HttpClient {
	private static instanceCached: DynamicData;

	private constructor() {
		super(process.env.GATSBY_API_ENDPOINT_DIRECTUS);
		this.instance.defaults.headers = {
			...this.instance.defaults.headers,
			common: {
				...this.instance.defaults.headers.common,
				Authorization: `Bearer ${process.env.GATSBY_API_KEY!}`,
			},
		};
	}

	static getInstance = () => {
		if (!DynamicData.instanceCached) {
			DynamicData.instanceCached = new DynamicData();
		}

		return DynamicData.instanceCached;
	};

	public getAvailibalityDirectusV2 = (date: string, clinicsIds: string, sourceToken: CancelTokenSource | undefined) =>
		this.instance.get<Availability>('/app/appointment-availabilities/for-many-clinics-v2', {
			cancelToken: sourceToken?.token,
			params: {
				clinicsIds,
				date,
			},
		});

	public postAppointmentDataDirectus = (clinicId: number, body: AppointmentData) =>
		this.instance.post(`/app/appointments/new-appointment/${clinicId}`, body);

	public confirmNewBookingWithOTPDirectus = (otp: string, appointmentId: string) =>
		this.instance.post(`/app/appointments/new-booking-confirm?otp=${otp}&appointmentId=${appointmentId}`);

	public resendOTP = (appointmentId: string) =>
		this.instance.post(`/app/appointments/resend-otp?appointmentId=${appointmentId}`);

	public connectClinicDirectus = (body: ConnectedClinic) =>
		this.instance.put(`/app/clinics/update-via-provider`, body);

	public getSiteMapDataDirectus = () => this.instance.get<SitemapItemData[]>('/app/sitemap-pages/pages-with-links');
}
export default DynamicData;
