import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
	label?: string;
	id: string;
	type?: string;
	inputmode?: string;
	// pattern?: string;
	name?: string;
	onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: () => void;
	value: string;
	disabled?: boolean;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	autoComplete?: 'off' | 'on';
	min?: string;
	placeholder?: string;
	isEmpty?: boolean;
	width?: number;
	maxWidth?: string;
	fullWidth?: boolean;
	iconSrc?: string;
	iconSize?: number;
	afterIconSrc?: string;
	readOnly?: boolean;
	hintText?: string;
	required?: boolean;
	isHideMode?: boolean;
	isNoMargin?: boolean;
	errorText?: string;
	isError?: boolean;
	inputRef?:
		| React.RefObject<HTMLInputElement>
		| React.MutableRefObject<React.RefObject<HTMLInputElement> | undefined>
		| undefined;
	onSelect?: (e: React.TouchEvent<HTMLInputElement>) => void;
	onMouseDown?: (e: React.MouseEvent<HTMLInputElement>) => void;
	beforeContent?: string;
	isAfterIcon?: boolean;
	isNoBorder?: boolean;
	isModal?: boolean;
	isMainHeader?: boolean;
	maxLength?: number;
}

const Input: React.FC<IProps> = ({
	label = null,
	id,
	type = 'text',
	inputmode = '',
	// pattern = '',
	name,
	onChange,
	onClick,
	value,
	disabled = false,
	onBlur,
	onFocus,
	autoComplete,
	min,
	placeholder,
	isEmpty,
	width = 396,
	maxWidth,
	fullWidth = false,
	iconSrc = null,
	iconSize = 24,
	afterIconSrc = null,
	readOnly = false,
	// hintText = null,
	required = false,
	isHideMode = false,
	isNoMargin = false,
	errorText = null,
	isError = false,
	inputRef = null,
	onSelect,
	onMouseDown,
	beforeContent = '',
	isNoBorder = false,
	isModal = false,
	isMainHeader = false,
	maxLength,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [isValue, setIsValue] = useState(value.length > 0);

	// const ref = useRef() as React.RefObject<HTMLInputElement>;

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetValue = event.target.value;
		setInputValue(targetValue);

		if (onChange) {
			onChange(targetValue, event);
		}
		if (targetValue === '') {
			setIsValue(false);
		} else {
			setIsValue(true);
		}
	};

	return (
		<InputWrapper fullWidth={fullWidth} width={width} maxWidth={maxWidth} noMargin={isNoMargin}>
			<LabelBlock isLabel={label !== null} onFocus={onFocus}>
				<Label htmlFor={id}>{label}</Label>
			</LabelBlock>
			<InputIconWrap>
				<InputStyled
					id={id}
					type={type}
					inputmode={inputmode}
					// pattern={pattern}
					name={name}
					onChange={changeHandler}
					onClick={onClick}
					value={inputValue}
					placeholder={placeholder}
					disabled={disabled}
					onBlur={onBlur}
					onFocus={onFocus}
					autoComplete={autoComplete}
					min={min || ''}
					isValue={isValue}
					isError={(isEmpty && !isValue) || isError || false}
					isIcon={iconSrc !== null}
					isBeforeSign={beforeContent !== ''}
					readOnly={readOnly}
					required={required}
					ref={inputRef}
					onSelect={onSelect}
					onMouseDown={onMouseDown}
					isAfterIcon={afterIconSrc !== null || isHideMode}
					isNoBorder={isNoBorder}
					isModal={isModal}
					isMainHeader={isMainHeader}
					// ref={ref}
					maxLength={maxLength}
				/>
				{iconSrc && <Icon src={iconSrc} size={iconSize} isNoBorder={isNoBorder} />}
				{beforeContent && (
					// <BeforeText isValue={isValue} isIcon={iconSrc !== null} onClick={() => ref.current?.focus()}>
					<BeforeText isValue={isValue} isIcon={iconSrc !== null} onClick={() => inputRef.current?.focus()}>
						{beforeContent}
					</BeforeText>
				)}
				{afterIconSrc && <AfterIcon src={afterIconSrc} onClick={() => onChange!('')} />}
			</InputIconWrap>
			{(isEmpty && !isValue) || isError ? (
				<RequireError>{errorText || `${'label'} is required`}</RequireError>
			) : (
				''
			)}
		</InputWrapper>
	);
};

const InputWrapper = styled.div<{
	fullWidth: boolean;
	width: number;
	maxWidth: string;
	noMargin: boolean;
}>`
	position: relative;
	width: ${({ fullWidth, width }) => (fullWidth ? '100%' : `${width}px`)};
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth};
		`};
	margin: ${({ noMargin }) => (noMargin ? '0' : '0 0 20px 0')};

	font-size: 16px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.main};
	outline: none;
	transition: color 0.25s ease-in;

	@media (max-width: 767px) {
		max-width: 100%;
		width: 100%;
	}
`;

const LabelBlock = styled.div<{ isLabel: boolean; onFocus: boolean }>`
	display: ${({ isLabel }) => (isLabel ? 'flex' : 'none')};
	align-items: center;
`;

const Label = styled.label`
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: ${({ theme }) => theme.palette.grey_text};
	text-transform: capitalize;
	transition: color 0.25s ease-in;
	margin: 0 0 4px;
`;

const InputIconWrap = styled.div`
	position: relative;
`;

const Icon = styled.img<{ size: number; isNoBorder: boolean }>`
	position: absolute;
	left: 16px;
	top: 14px;
	width: ${({ size }) => `${size}px`};
	// height: ${({ size }) => `${size}px`};
	max-width: 100%;
	border-radius: 4px;
	overflow: hidden;
`;

const BeforeText = styled.p<{ isValue: boolean; isIcon: boolean }>`
	position: absolute;
	top: 14px;
	left: ${({ isIcon }) => (isIcon ? '45px' : '10px')};
	padding: 0 4px;

	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: ${({ theme, isValue }) => (isValue ? 'transparent' : `${theme.palette.modal_bg_text}`)};
	background-color: ${({ theme, isValue }) => (isValue ? 'transparent' : `${theme.palette.white}`)};
	transition: all 0.25s ease-in;
`;

const AfterIcon = styled.img`
	position: absolute;
	right: 20px;
	top: 15px;
	width: 17px;
	height: 17px;
	cursor: pointer;
`;

interface IInput {
	isError: boolean;
	isIcon: boolean;
	isValue: boolean;
	isBeforeSign: boolean;
	isAfterIcon: boolean;
	isNoBorder: boolean;
	isModal: boolean;
	isMainHeader: boolean;
}

const InputStyled = styled.input<IInput>`
	position: relative;
	caret-color: ${({ theme }) => theme.palette.main};
	width: 100%;
	height: ${({ isNoBorder }) => (isNoBorder ? '50px' : '48px')};
	padding: ${({ isIcon, isBeforeSign, isMainHeader }) =>
		isIcon
			? '14px 16px 15px 45px'
			: isBeforeSign
			? '14px 16px 15px 22px'
			: isMainHeader
			? '14px 16px 15px 22px'
			: '14px 16px 15px 9px'};
	padding-right: ${({ isAfterIcon }) => isAfterIcon && '45px'};

	background: ${({ theme }) => theme.palette.white};
	border: ${({ theme, isNoBorder, isValue, isModal, isMainHeader }) =>
		isNoBorder
			? 'none'
			: isModal
			? `1px solid ${theme.palette.items_border}`
			: isValue || isMainHeader
			? `2px solid ${theme.palette.purp}`
			: `1px solid ${theme.palette.inactive}`};
	border-radius: ${({ isMainHeader }) => (isMainHeader ? '3px' : '4px')};
	outline: none;
	font-weight: normal;
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme }) => theme.palette.main};
	transition: 0.3s ease-in;
	box-shadow: ${({ isMainHeader }) => (isMainHeader ? '0px 0px 10px 2px rgba(66, 75, 236, 0.18)' : 'none')};

	&::-webkit-input-placeholder {
		color: ${({ theme, isModal }) => (isModal ? `${theme.palette.grey_text}` : `${theme.palette.purp}`)};
		font-size: 16px;
		font-weight: 300;
	}

	&:focus {
		outline: none;
		border: ${({ theme, isNoBorder }) => (isNoBorder ? 'none' : `2px solid ${theme.palette.purp}`)};
		box-shadow: ${({ isMainHeader }) => (isMainHeader ? '0px 0px 10px 2px rgba(66, 75, 236, 0.18)' : 'none')};
		padding: ${({ isIcon, isBeforeSign, isMainHeader }) =>
			isIcon
				? '14px 16px 15px 44px'
				: isBeforeSign
				? '14px 16px 15px 21px'
				: isMainHeader
				? '14px 16px 15px 22px'
				: '14px 16px 15px 8px'};

		&::-webkit-input-placeholder {
			color: transparent;
			opacity: 0.7;
		}

		& ~ p {
			top: -10px;
			left: 10px;
			color: ${({ theme }) => ` ${theme.palette.purp}`};
			background-color: ${({ theme }) => `${theme.palette.white}`};
			z-index: 1;
		}
	}

	${({ isError }) =>
		isError &&
		css`
			border: ${({ theme }) => `1px solid ${theme.palette.fail}`};

			& ~ p {
				top: -10px;
				left: 10px;
				color: ${({ theme }) => `${theme.palette.fail}`};
				background-color: ${({ theme }) => `${theme.palette.white}`};
				z-index: 1;
			}
		`};
`;

const RequireError = styled.p`
	position: absolute;
	bottom: -17px;
	left: 0;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;

	color: ${({ theme }) => theme.palette.fail};
`;

export default Input;
